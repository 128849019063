<template>
	<div class='a-trips-search' :class='{[name]: name, "default": !animation, "animationDisabled": animationDisabled === true}'>
		<div class='a-trips-search-icon'>
			<span class='a-trips-search-icon-background' v-html="require(`!html-loader!@/assets/preloader/${name}.svg`)"></span>
			<span class='a-trips-search-icon-animation'>
				<span v-html="require(`!html-loader!@/assets/preloader/${animation ? `${animation}-animation.svg` : `animation.svg`}`)"></span>
				<span class='double' v-html="require(`!html-loader!@/assets/preloader/animation.svg`)" v-if='!animation'></span>
			</span>
		</div>
		<p class='a-trips-search-title'>{{title}}</p>
		<p class='a-trips-search-text'>{{text}}</p>
		<slot name='list' />
	</div>
</template>

<script>
	export default {
		props: ["name", "title", "text", "animation", "animationDisabled"]
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips-search {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 40px;
		margin-bottom: 40px;
		&-icon {
			margin-bottom: 30px;
			svg {
				position: relative;
				z-index: 1;
			}
			&-animation {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 0;
			}
		}
		&-title {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-sm ) {
				font-size: 20px;
				margin-bottom: 30px;
			}
		}
		&-text {
			font-size: 14px;
			font-weight: 400;
			text-align: center;
			max-width: 520px;
			margin-bottom: 30px;
			@include minw( $grid-breakpoints-sm ) {
				font-size: 16px;
			}
		}
		&-list {
			text-align: left;
			margin-top: 40px;
			&-item {
				font-size: 16px;
				font-weight: 400;
				color: rgba($black, .5);
				&:not(:last-child) {
					margin-bottom: 5px;
				}
				&:before {
					content: '';
					margin-right: 10px;
					width: 10px;
					height: 10px;
					display: inline-block;
					background-color: rgba($black, .5);
					mask-image: url('../../../../assets/svg/check.svg');
					mask-size: contain;
				}
			}
		}
		.app-btn {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
			}
		}
		&.flights {
			.a-trips-search {
				&-icon {
					width: 250px;
					&-animation {
						svg {
							animation: rotatingFlights 3s linear infinite;
						}
					}
				}
			}
		}
		&.default {
			.a-trips-search {
				&-icon {
					&-animation {
						top: 46px;
						left: -65px;
						right: -65px;
						overflow: hidden;
						span {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							display: block;
							animation: translateBack 12s linear infinite;
							&.double {
								animation: translateFront 12s linear infinite;
							}
						}
						svg {
							width: auto;
						}
					}
				}
			}
		}
		&.hotels {
			.a-trips-search {
				&-icon {
					width: 80px;
					@include minw( $grid-breakpoints-xs ) {
						width: 120px;
					}
				}
			}
		}
		&.transfers,
		&.cars {
			.a-trips-search {
				&-icon {
					width: 150px;
					height: 165px;
					display: flex;
					align-items: flex-end;
					@include minw( $grid-breakpoints-xs ) {
						width: 190px;
						height: 205px;
					}
					&-background {
						width: 100%;
					}
				}
			}
		}
		&.cars {
			.a-trips-search {
				&-icon {
					width: 100px;
					@include minw( $grid-breakpoints-xs ) {
						width: 150px;
					}
					&-animation {
						transform: scaleX(-1);
					}
				}
			}
		}
		&.insurance {
			.a-trips-search {
				&-icon {
					width: 82px;
					@include minw( $grid-breakpoints-xs ) {
						width: 155px;
					}
					&-animation {
						z-index: 1;
						width: 40px;
						height: 40px;
						margin: 0 auto;
						margin-top: 30px;
						@include minw( $grid-breakpoints-xs ) {
							width: 76px;
							height: 76px;
							margin-top: 56px;
						}
						svg {
							fill: $white;
							animation: rotatingFlights 3s linear infinite;
						}
					}
				}
			}
		}
	}

	.animationDisabled {
		* {
			animation: none !important;
		}
	}

	@keyframes rotatingFlights {
		from { transform: rotate(0deg); }
		to { transform: rotate(-360deg); }
	}
	@keyframes translateBack {
		from { transform: translateX(0%); }
		to { transform: translateX(100%); }
	}
	@keyframes translateFront {
		from { transform: translateX(-100%); }
		to { transform: translateX(0%); }
	}
</style>
